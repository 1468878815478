import React from "react";

import styled from "styled-components";
import arrowLeft from 'src/assets/img/arrowLeft.svg';
import { Txt } from "src/ui/text";
import { yupResolver } from "@hookform/resolvers/yup";
import { object } from "yup";
import { Controller, useForm } from "react-hook-form";
import Input from "src/ui/inputs/Input";
import styles from "./style.module.sass";
import { UserColors } from "src/constants";
import { TPageSocialLinkList, TPublicPage, TSliderColorList, TSocialLinkPositionList } from "src/types";
import { PhotoUploader } from "./Uploader/PhotoUploader";
import { CoverUploader } from "./Uploader/CoverUploader";
import { SliderColors } from "src/ui/slider/SliderColors";
import { useTranslation } from "react-i18next";
import { Selector } from "src/ui/selector";
import { FontOptions } from "./contstants";
import { TOnAddLink, TOnUpdatePage } from "../types";
import { SortableSocials } from "./SortableSocials";
import { Loader } from "src/ui/loader";
import { AddSocialLink } from "./AddSocialLink";
import { StandardModal } from "src/components/modals/StandardModal";


type TProps = Readonly<{
    page: TPublicPage;
    socialLinks: TPageSocialLinkList | null;
    onDeleteLink: (linkId: string) => void;
    onAddLink: TOnAddLink;
    onSortLinks: (list: TSocialLinkPositionList) => void;
    onSubmit: TOnUpdatePage;
    onClose: () => void;
}>;

const BackButton = styled.button`
    background-image: url(${arrowLeft})};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 24px;
    width: 24px;
    background-color: transparent;
    margin-right: 20px;
`

const Header = styled.div`
    justify-content: flex-start;
    flex-direction: row;
    display: flex;
    align-items: center;
`
const Container = styled.div`
    margin-top: 30px;
`

type TForm = Readonly<{
    name: string;
    tagline: string;
    title: string;
    description: string;
    photo: File | null;
    coverImage: File | null;
    coverColor: string | null;
    backgroundColor: string | null;
    fontColor: string | null;
    font: string | null;
    pageType: string | "1";
}>;

const EditDefaultBlock_: React.FC<TProps> = ({
    page,
    socialLinks,
    onDeleteLink,
    onAddLink,
    onSortLinks,
    onSubmit,
    onClose
}: TProps) => {
    const { t } = useTranslation();

    const {
        page_name, page_title, page_description, page_photo,
        page_cover, page_cover_color, page_tagline, page_background_color,
        page_font_color, page_font, id, page_type
    } = page;
    const [pageCoverUrl, setPageCoverUrl] = React.useState<string | null>(page_cover);

    const {
        control,
        formState,
        reset,
        handleSubmit,
        resetField,
      } = useForm<TForm>({
        mode: "all",
        defaultValues: {
            name: page_name ?? "",
            tagline: page_tagline ?? "",
            title: page_title,
            description: page_description,
            photo: null,
            coverImage: null,
            coverColor: page_cover_color,
            backgroundColor: page_background_color,
            fontColor: page_font_color,
            font: page_font,
            pageType: page_type,
        },
        resolver: yupResolver(object({
        })),
    });

    const coverColorOptions = React.useMemo<TSliderColorList>(
        () => Object.values(UserColors).map(x => ({
                value: x,
                bgColor: x,
        })),
        [],
    );

    const close = React.useCallback(
        () => {
            reset();
            onClose();
        },
        [onClose, reset],
    );

    const submit = React.useCallback(
        () => {
            handleSubmit(fields => onSubmit(id, {
                page_name: fields.name || undefined,
                page_title: fields.title || undefined,
                page_description: fields.description || undefined,
                page_tagline: fields.tagline || undefined,
                page_photo: fields.photo || undefined,
                page_cover: fields.coverImage ? fields.coverImage : (pageCoverUrl === null ? "" : undefined),
                page_cover_color: fields.coverColor || undefined,
                page_background_color: fields.backgroundColor || undefined,
                page_font_color: fields.fontColor || undefined,
                page_font: fields.font || undefined,
                page_type: fields.pageType || "1",
            }))();
            close();
        },
        [close, handleSubmit, id, onSubmit, pageCoverUrl],
    );

    const onRemoveCoverImage = React.useCallback(
        () => {
            setPageCoverUrl(null);
            resetField("coverImage");
        },
        [resetField],
    );

    const addLink = React.useCallback<(link: string) => void>(
        link => {
            onAddLink(`${page._embedded.social_link.length}`, page.id, link);
        },
        [onAddLink, page._embedded.social_link.length, page.id]
    );

    return (
        <StandardModal
            headerElement={
                <Header>
                    <BackButton onClick={close} />
                    <Txt textStyle='h1'>{t('TITLE_HEAD_BLOCK')}</Txt>
                </Header>
            }
            onSubmit={submit}
            onClose={onClose}
            hasCloseIcon={false}
        >
            <Container>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Txt textStyle='h2'>{t('COVER')}</Txt>
                    <Controller
                        name="coverImage"
                        control={control}
                        render={({field}) =>
                            <>
                                <Txt marginTop="30px" textStyle='body_14'>{t('COVER_IMAGE')}</Txt>
                                <CoverUploader
                                    url={pageCoverUrl}
                                    imageFile={field.value}
                                    onSetImageFile={field.onChange}
                                    onRemove={onRemoveCoverImage}
                                />
                            </>
                        }
                    />
                    <Controller
                        name="coverColor"
                        control={control}
                        render={({field}) =>
                            <SliderColors
                                header={t('COVER_COLOR')}
                                list={coverColorOptions}
                                onClick={field.onChange}
                                activeValue={field.value ?? ""}
                                width="34px"
                                height="34px"
                                itemsInRow={6}
                                marginTop="20px"
                            />
                        }
                    />
                    <Txt textStyle='h2'>{t('TITLE_BIO')}</Txt>
                    <Controller
                        name="photo"
                        control={control}
                        render={({field}) =>
                            <PhotoUploader
                                url={page_photo}
                                imageFile={field.value}
                                onSetImageFile={field.onChange}
                            />
                        }
                    />
                    <Controller
                        name="name"
                        control={control}
                        render={({field}) =>
                            <Input
                                type="text"
                                name={field.name}
                                value={field.value}
                                error={formState.errors.name?.message}
                                label={t('PAGE_NAME')}
                                className={styles.input}
                                onBlur={field.onBlur}
                                onChange={field.onChange}
                            />
                        }
                    />
                    <Controller
                        name="tagline"
                        control={control}
                        render={({field}) =>
                            <Input
                                type="text"
                                name={field.name}
                                value={field.value}
                                error={formState.errors.tagline?.message}
                                label={t('PAGE_TAGLINE')}
                                className={styles.input}
                                onBlur={field.onBlur}
                                onChange={field.onChange}
                            />
                        }
                    />
                    <Controller
                        name="title"
                        control={control}
                        render={({field}) =>
                            <Input
                                type="text"
                                name={field.name}
                                value={field.value}
                                error={formState.errors.title?.message}
                                label={t('PAGE_TITLE')}
                                className={styles.input}
                                onBlur={field.onBlur}
                                onChange={field.onChange}
                            />
                        }
                    />
                    <Controller
                        name="description"
                        control={control}
                        render={({field}) =>
                            <Input
                                type="text"
                                name={field.name}
                                value={field.value}
                                error={formState.errors.description?.message}
                                label={t('PAGE_DESCRIPTION')}
                                className={styles.input}
                                onBlur={field.onBlur}
                                onChange={field.onChange}
                            />
                        }
                    />
                    <AddSocialLink onAddLink={addLink}/>
                    {socialLinks === null ? (
                        <Loader marginBottom={30}/>
                    ) : (socialLinks.length > 0 && (
                        <SortableSocials
                            links={socialLinks}
                            onDelete={onDeleteLink}
                            onSortLinks={onSortLinks}
                        />
                    ))}
                    <Txt textStyle='h2'>{t('TITLE_PAGE_SETTINGS')}</Txt>
                    <Controller
                        name="backgroundColor"
                        control={control}
                        render={({field}) =>
                            <SliderColors
                                header={t('BACKGROUND_COLOR')}
                                list={coverColorOptions}
                                onClick={field.onChange}
                                activeValue={field.value ?? ""}
                                width="34px"
                                height="34px"
                                itemsInRow={6}
                                marginTop="30px"
                            />
                        }
                    />
                    <Controller
                        name="font"
                        control={control}
                        render={({field}) =>
                            <Selector
                                label={t('FONT')}
                                selectedValue={field.value ?? ""}
                                list={FontOptions}
                                onSelect={field.onChange}
                            />
                        }
                    />
                    <Controller
                        name="fontColor"
                        control={control}
                        render={({field}) =>
                            <SliderColors
                                header={t('FONT_COLOR')}
                                list={coverColorOptions}
                                onClick={field.onChange}
                                activeValue={field.value ?? ""}
                                width="34px"
                                height="34px"
                                itemsInRow={6}
                                marginTop="30px"
                            />
                        }
                    />
                    <Controller
                        name="pageType"
                        control={control}
                        render={({field}) =>
                            <Input
                                type="hidden"
                                name={field.name}
                                value={field.value}
                                error={formState.errors.tagline?.message}
                                label={t('PAGE_TAGLINE')}
                                className={styles.input}
                                onBlur={field.onBlur}
                                onChange={field.onChange}
                            />
                        }
                    />
                </form>
            </Container>
        </StandardModal>
    )
};

export const EditDefaultBlock = React.memo(EditDefaultBlock_);
