import React from "react";

import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import { Colors, TabletScreenWidth } from "src/constants";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import { TTextStyle } from "src/types";
import { StandardButton } from "src/ui/buttons/StandardButton";
import { Txt } from "src/ui/text";
import styled from "styled-components";

type TProps = Readonly<{
    onSubmit: () => void;
    onClose: () => void;
    children: React.ReactNode;
    onCancel?: (() => void) | null;
    submitLabel?: string;
    cancelLabel?: string;
    isSubmitDisabled?: boolean;
    header?: string;
    headerTextStyle?: TTextStyle;
    headerClassName?: string;
    headerElement?: React.ReactElement;
    hasCloseIcon?: boolean;
}>;

const ChildrenContainer = styled.div`
    margin-bottom: 90px;
`

const StandardModal_: React.FC<TProps> = ({
    header,
    onSubmit,
    children,
    onClose,
    onCancel = null,
    submitLabel,
    cancelLabel,
    isSubmitDisabled = false,
    headerElement,
    headerTextStyle,
    headerClassName,
    hasCloseIcon = true
}: TProps) => {
    const { t } = useTranslation();
    const {width} = useWindowDimensions();

    const isFullScreen: true | undefined = (width <= TabletScreenWidth) || undefined;
    return (
        <Modal
            show
            fullscreen={isFullScreen}
            size={isFullScreen ? undefined : "lg"}
            onHide={onClose || undefined}
            centered
        >
            <Modal.Header className="pt-4 px-4 pb-0 border-0" closeButton={hasCloseIcon}>
                {!!header && (
                    <Modal.Title className={headerClassName}>
                        <Txt textStyle={headerTextStyle}>{header}</Txt>
                    </Modal.Title>
                )}
                {headerElement}
            </Modal.Header>
            <Modal.Body className="px-4 py-0">
                {children && (
                    <ChildrenContainer>
                        {children}
                    </ChildrenContainer>
                )}
            </Modal.Body>
            <Modal.Footer className="border-0 flex-nowrap p-4">
                {onCancel && (
                    <StandardButton
                        height="51px"
                        width="100%"
                        className="btn-secondary"
                        labelStyle="h3"
                        label={cancelLabel ?? t('BTN_DELETE')}
                        onClick={onCancel}
                        labelColor={Colors.sysBlue1}
                    />
                 )}
                <StandardButton
                    isDisabled={isSubmitDisabled}
                    height="51px"
                    width="100%"
                    className="btn-primary"
                    labelStyle="h3"
                    label={submitLabel ?? t('SAVE')}
                    onClick={onSubmit}
                 />
            </Modal.Footer>
        </Modal>
    )
};

export const StandardModal = React.memo(StandardModal_);
