import { FC } from "react";
import c from "classnames";

import Logo from "src/ui/images/Logo";
import SignInForm from "src/components/forms/SignInForm";

import s from "./style.module.sass";
import * as process from "node:process";

const PageSignIn: FC = () => {
  return (
    <>
      <h1 className={c(s.title)}>Log in NTMY!!</h1>
      <Logo className={c(s.logo)} />
      <SignInForm />
    </>
  );
};

export default PageSignIn;
