export type TTextStyle = 'h0' | 'h1' | 'h2' | 'h3' | 'body_16' | 'uppercase' |
'body_14' | 'body_12' | 'body_secondary' | 'title_lg' | 'title_xlg' | 'title_md' |
'body_semibold' | 'help_text' | 'page_address';

export enum CardType {
    Physical = "1",
    Virtual = "2",
}

type TInactiveCard = Readonly<{
    message?: string, // error message
}>;

export type TLinkSelf = Readonly<{
  _links: {
    self: {
      href: string,
    }
  }
}>;

export type TRegisteredCard = TLinkSelf & TInactiveCard & Readonly<{
    key: string;  //unique card id
    id?: string;
    users_id?: string;
    card_type?: CardType;
    page_address?: string | null;
}>;

export type TUserInfo = TLinkSelf & Readonly<{
  user_id: string;
  username: string;
  email: string;
  state: string;  // state = '1' - for active user with verified email
}>;

export type TUserInfoList = ReadonlyArray<TUserInfo>;

export type TPage = TLinkSelf & Readonly<{
  id: string;
  owner_id: string;
  page_address: string;
  page_address_full: string;
  page_title: string;
  page_description: string;
  page_preview: string | null;
  page_name: string | null;
  page_photo: string | null;
  page_cover: string | null;
  page_background: string | null;
  page_background_color: string | null;
  page_font: string | null;
  page_font_color: string | null;
  page_tagline: string | null;
  page_type: string | "1";
  page_cover_color: string | null;
  roles_id: string | null; // RoleOptions
}>;

export type TPageList = ReadonlyArray<TPage>;

export type TSliderItem = Readonly<{
  value: string;
  label: string;
}>;

export type TSliderList= ReadonlyArray<TSliderItem>;

export type TSliderColorItem = Readonly<{
  value: string;
  bgColor: string;
}>;

export type TSliderColorList= ReadonlyArray<TSliderColorItem>;

export type TImgSize = Readonly<{
  size: number;
  numberInRow: number;
}>;

export enum PageBlockType {
  Simple = "1",
  Video = "2",
  Gallery = "3",
  Items = "4",
  Music = "5",
  Events = "6",
};

export type TBlockSettings = Readonly<{
  block_id: string;
  settings_value: string | null;
  settings_id: string | null;
  settings_name: string | null;
}>;

export type TBlockSettingsList = ReadonlyArray<TBlockSettings>;

export type TBlockImage = Readonly<{
  id: string;
  block_image_url: string;
}>;

export type TBlockImageList = ReadonlyArray<TBlockImage>;

export enum ImageType {
  NoImage = "0",
  Small = "1",
  Medium = "2",
  Big = "3",
  Carousel = "4",
};

export interface IPageBlockBase {
  block_id: string;
  block_type: string; // PageBlockType
  block_title: string;
  block_description: string;
  block_position: string;
  block_url: string;
  block_url_text: string;
  image_type: string; // ImageType
  settings: TBlockSettingsList;
  images: TBlockImageList;
}

export interface IPageBlock extends IPageBlockBase {
  id: string;
}

export type TPageBlockList = Array<IPageBlock>;

export interface IPageBlockDraggable extends IPageBlockBase {
  id: number;
}

export type TPageBlockDraggableList = Array<IPageBlockDraggable>;

export type TPageSocialLink = TLinkSelf & Readonly<{
  id: string;
  pages_id: string;
  social_link: string;
  link_position: string;
}>;

export type TPageSocialLinkList = ReadonlyArray<TPageSocialLink>;

export type TPublicPage = TLinkSelf & Readonly<{
  id: string;
  owner_id: string;
  date_create: string; // for example "2023-03-02 09:35:46"
  date_update: string;
  page_address: string;
  page_title: string;
  page_description: string;
  page_preview: string;
  page_name: string | null;
  page_photo: string;
  page_cover: string | null;
  page_cover_color: string | null;
  page_background: string | null;
  page_background_color: string | null;
  page_font: string | null;
  page_font_color: string | null;
  page_tagline: string | null;
  page_type: string | "1";
  username: string;
  blocks: TPageBlockList;
  _embedded: Readonly<{
    social_link: TPageSocialLinkList;
  }>
}>;

export type TImageItem = Readonly<{
  id: string;
  block_image_url: string;
}>;

export type TImageList = ReadonlyArray<TImageItem>;

export type TBlockPosition = Readonly<{
  id: string;
  block_position: string;
}>;

export type TBlockPositionList = ReadonlyArray<TBlockPosition>;

export type TSelectItem = Readonly<{
  value: string;
  label: string;
  style?: Readonly<{
    fontFamily?: string;
  }>;
}>;

export type TSelectList = ReadonlyArray<TSelectItem>;

export type TOnInputCb = (e: React.ChangeEvent<HTMLInputElement>) => void;

export type TSocialLinkPosition = Readonly<{
  id: string;
  link_position: string;
}>;

export type TSocialLinkPositionList = ReadonlyArray<TSocialLinkPosition>;

export type TIcon = Readonly<{
  src: string;
  onClick?: () => void;
  onLongClick?: () => void;
}>;

export type TIconList = ReadonlyArray<TIcon>;

export type TRegisteredCardList = ReadonlyArray<TRegisteredCard>;

export type RegisterRequest = {
  email: string;
  password: string;
  card_code?: string;
};
